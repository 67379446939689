<template>

  <div>
    <!-- <div @click="_showModal()" class="card card-body mb-2" data-bs-toggle="modal" :data-bs-target="'#medcasemodal' + card.id"> -->
      <div class="card card-body mb-2">

      <div class="text-muted" style="display: flex; justify-content: space-between">
          <a :href="'/medical_cases/' + card.id">{{$t('case')}}-{{card.id}} </a>
          <!-- 
            a :href="'/medical_cases/' + card.id+'/feed'">History</a> 
          <button class="btn btn-link" @click='loadNewsFeed(card.id)' v-b-toggle.history_bar>History</button>
          -->
      </div>

      <p class="card-text">
        {{ card.description }}
      </p>
      
      <!-- Button trigger modal -->
      <button type="button" class="btn btn-info btn-sm" data-bs-toggle="modal" :data-bs-target="'#medcasemodal' + card.id"  @click="_showModal()">
        {{ $t('more')}}...
      </button> 
      
    </div>

    <!-- Sidebar
    <b-sidebar
        id="history_bar"
        :title="'Case\'s Diary ' + card.id"
        backdrop-variant="dark"
        backdrop
        shadow
        @hidden="closeSidebar"
      >   

        <div v-for="news in feed" :key="news.id">

          <div class="card" _style="width: 18rem;">
            <div class="card-body">
              <p class="card-subtitle mb-2 text-muted">
                    {{news.user}} {{news.event}}d {{news.type}} {{ news.updated}} ago.<br/>
              </p>
              <p class="card-text" 
                  v-for="(value, field) in news.changes" 
                  v-if="!!value[0] && !!value[1] && field != 'updated_at'" 
                  :key="field+value">
                      {{field}} changed from {{value[0]}} to {{value[1]}}<br/>
              </p>
            </div>
          </div>

        </div>

      </b-sidebar>
    -->
    
    <!-- Modal -->
    <div class="modal fade lg" :id="'medcasemodal' + card.id" tabindex="0" z-index="999" role="dialog" :aria-labelledby="'medcasemodal' + card.id" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <div >
              <div class="text-muted">
                  <a :href="'/medical_cases/' + card.id"  >{{$t('case')}}-{{card.id}} </a> 
              </div>        
            </div> 
          </div>
          <div class="modal-body">
            <table class="display_table">
              <caption style="caption-side: top; color: red;">{{ $t('summary') }}</caption>
              <tbody>
                <tr v-if="card.age">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.age') + ' / ' + $t('activerecord.attributes.medical_case.gender')}}:</strong></th>
                  <td style="word-wrap: break-word;min-width: 160px;max-width: 160px;">{{ card.age }} / {{ card.gender }}</td>
                </tr>
                <tr v-if="card.description">
                  <th scope="row"><strong>{{ $t('summary') }}:</strong></th>
                  <td>{{ card.description }}</td>
                </tr>
                <tr>
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.reasons_for_case_presentation') }}:</strong></th>
                  <td>{{ card.reasons_for_case_presentation }}</td>
                </tr>
                <tr>
                <th scope="row"><strong> Diagnoses</strong></th>
                <td>
                  <ul>
                      <li v-for="diag in card.diagnoses" :key="diag.message">
                        {{ diag.nice_description }}
                      </li>
                  </ul>
                </td>
              </tr>

              <tr>
                <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.current_treatment') }}:</strong></th>
                <td> {{ card.current_treatment }}</td>
              </tr>
                <tr>
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.recommendations') }}:</strong></th>
                  <td>
                    <ul>
                      <li v-for="rec in card.recommendations" :key="rec.message">
                        <div v-html="rec.rich_content.body"></div>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
          </table>

          <table class="display_table">
          <caption style="caption-side: top; color: red;">{{ $t('activerecord.attributes.medical_case.medical_history') }}</caption>
              <tbody>
                <tr v-if="card.history_present_illness">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.history_present_illness') }}:</strong></th>
                  <td _style="word-wrap: break-word;min-width: 160px;max-width: 160px;" >{{ card.history_present_illness }}</td>
                </tr>
                <tr v-if="card.medical_history">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.medical_history') }}:</strong></th>
                  <td >{{ card.medical_history }}</td>
                </tr>
                <tr v-if="card.pre_existing_conditions">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.pre_existing_conditions') }}:</strong></th>
                  <td >{{ card.pre_existing_conditions }}</td>
                </tr>
                <tr v-if="card.family_history">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.family_history') }}:</strong></th>
                  <td >{{ card.family_history }}</td>
                </tr>
                <tr v-if="card.previous_radiation_therapy">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.previous_radiation_therapy') }}:</strong></th>
                  <td >{{ card.previous_radiation_therapy }}</td>
                </tr>
                <tr v-if="card.previous_cancer_treatments">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.previous_cancer_treatments') }}:</strong></th>
                  <td >{{ card.previous_cancer_treatments }}</td>
                </tr>
                <tr v-if="card.current_medications">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.current_medications') }}:</strong></th>
                  <td >{{ card.current_medications }}</td>
                </tr>
                <tr v-if="card.current_treatment">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.current_treatment') }}:</strong></th>
                  <td >{{ card.current_treatment }}</td>
                </tr>
                <tr v-if="card.clinical_examination_results">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.clinical_examination_results') }}:</strong></th>
                  <td >{{ card.clinical_examination_results }}</td>
                </tr>
                <tr v-if="card.biopsy_type_organ_date">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.biopsy_type_organ_date') }}:</strong></th>
                  <td >{{ card.biopsy_type_organ_date }}</td>
                </tr>
                <tr v-if="card.staging_examination_result_CT">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.staging_examination_result_CT') }}:</strong></th>
                  <td >{{ card.staging_examination_result_CT }}</td>
                </tr>
                <tr v-if="card.staging_examination_result_MRT">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.staging_examination_result_MRT') }}</strong></th>
                  <td >{{ card.staging_examination_result_MRT }}</td>
                </tr>
                <tr v-if="card.staging_examination_type_XRAY">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.staging_examination_type_XRAY') }}</strong></th>
                  <td >{{ card.staging_examination_type_XRAY }}</td>
                </tr>
                <tr v-if="card.staging_examination_ultrasound">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.staging_examination_ultrasound') }}</strong></th>
                  <td >{{ card.staging_examination_ultrasound }}</td>
                </tr>
                <tr v-if="card.staging_examination_other">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.staging_examination_other') }}</strong></th>
                  <td >{{ card.staging_examination_other }}</td>
                </tr>
                <tr v-if="card.tnm_stage_and_organs_involved">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.tnm_stage_and_organs_involved') }}</strong></th>
                  <td >{{ card.tnm_stage_and_organs_involved }}</td>
                </tr>
                <tr v-if="card.pathology_histology_mol_markers">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.pathology_histology_mol_markers') }}</strong></th>
                  <td >{{ card.pathology_histology_mol_markers }}</td>
                </tr>
                <tr v-if="card.histology_and_molecular_pathology">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.histology_and_molecular_pathology') }}</strong></th>
                  <td >{{ card.histology_and_molecular_pathology }}</td>
                </tr>
                <tr v-if="card.other_relevant_information">
                  <th scope="row"><strong>{{ $t('activerecord.attributes.medical_case.other_relevant_information') }}</strong></th>
                  <td >{{ card.other_relevant_information }}</td>
                </tr>
              </tbody>
          </table>
          
          <!-- <table class="display_table">
          <caption style="caption-side: top; color: red;">Demographic Information</caption>
              <tbody>
                <tr v-if="card.age">
                  <th scope="row"><strong>Age:</strong></th>
                  <td >{{ card.age }}</td>
                </tr>
              </tbody>
          </table> -->


          <div>
            <caption style="caption-side: top; color: red;"> {{ $t('attachments') }}</caption>
            <div  class="container-fluid p-4 bg-secondary">
              <div class="row">
                <silent-box :gallery="attachments">
                    <template v-slot:silentbox-item="{ silentboxItem }">
                      <div class="col">
                        <img  width="150px" :src="silentboxItem.thumbnail" style="border:5px solid white; margin: 10px;">
                      </div>
                    </template>
                </silent-box> 
              </div>
              </div>
        </div>
          </div>
          <div class="modal-footer">
            <div>
              <a :href="'/medical_cases/' + card.id +'/recommendations/new'">
                <button type="button" class="btn btn-danger">
                  {{ $t('submit_new_recommendation') }}
                </button>
              </a>
              <!-- <a :href="'/medical_cases/' + card.id +'/comments/new'">
                <button type="button" class="btn btn-danger">
                  Comment
                </button>
              </a> -->
          </div>
        <button class="btn btn-primary" data-bs-dismiss="modal" @click="_closeModal()"> {{ $t('close') }}</button>

          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import Rails from '@rails/ujs'

export default {
  //components: { modal },
  props: ['card'],
  data: function() {
    return {
      //name: this.card.description,
      feed: [],
      attachments: [],
    }
  },
  // computed: {
  //   truncatedString(originalString, maxCharacters) {
  //     if (originalString.length <= maxCharacters) {
  //       return originalString;
  //     } else {
  //       return originalString.slice(0, maxCharacters) + "...";
  //     }
  //   }
  // },

  methods: {
    _closeSidebar: function(event) {
      this.show_history = false
    },
    _showModal: function(event) {
        this.loadAttachments();
        //console.log("showing the modal"+this.card.id);
        //document.body.classList.add("modal-open");
    },
    _closeModal: function(event) {
        //console.log("closing the modal"+this.card.id);
        //document.body.classList.remove("modal-open");
        //console.log("ID: " + import.meta.env.VITE_VIDEOSDK_MEETING_ID)
        //console.log("KEY: " + import.meta.env.VITE_VIDEOSDK_API_KEY)


    },
    _loadNewsFeed: function(id){
      this.show_history = true;
      //in Rails.ajax the context this will be switched. Thus savong the old context
      var that = this;
      
      Rails.ajax({
          type: 'GET', 
          url: `/medical_cases/${that.card.id}/feed`,
          dataType: "json",
          response: [],

          success: function(response){
            //console.log(`It works. payload: ID: ${id} Length: `+response[0].path) // I'm text inside the component.
            that.feed=response
          },
          error: function(response){
            console.log("There was an Error in the call: "+response.toString) // I'm text inside the component.
          }
          })
        ;
    },
    loadAttachments: function(){
      //in Rails.ajax the context this will be switched. Thus savong the old context
      var that = this;
      
      Rails.ajax({
          type: 'GET', 
          url: `/medical_cases/${that.card.id}/attachments`,

          dataType: "json",
          response: [],

          success: function(response){
            //console.log(`It works. payload: ID: ${that.card.id} Length: `+response[0].path) // I'm text inside the component.
            that.attachments=response
          },
          error: function(response){
            console.log("There was an Error in the call: "+response) // I'm text inside the component.
          }
          })
        ;
    },
  },

}
</script>

<style scoped>

.ghost{
  background: lightgray;
  border-radius: 6px;
}
.ghost > div{
  visibility: hidden;
}
.drag > div{
  transform: rotate(5deg);
}
.image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
  }
  .center {
    text-align:center;
    width: 50%;
    margin: 0 auto;
  }

  .overlay {
    position: fixed; /* Positioning and size */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(128,128,128,0.5); /* color */
    display: none; /* making it hidden by default */
  }

   .modal_test {
    position: fixed; /* positioning in center of page */
    top: 50vh;
    left: 50vw;
    transform: translate(-50%,-50%);
    height: 400px; /* size */
    width: 600px;
    background-color: white; /* background color */
  } 

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  /*https://codepen.io/zvona/pen/YxQzEO */
</style>
    