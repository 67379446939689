/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// load the stimulus controller file index.js
import '../controllers/index.js'; 

import './jquery.js'
import './select2';
import './dark_mode.js'
//import $ from 'jquery';

//Import the styles
// entrypoiont for all imports
import '../styles/medicalboard.scss';
import 'bootstrap'



// Rails JS
import Rails from '@rails/ujs';
Rails.start();

import { start as startActiveStorage } from '@rails/activestorage';
startActiveStorage();


import '@hotwired/turbo-rails'
import 'trix'
import '@rails/actiontext'
import 'chartkick/chart.js'
import './datetimepicker_flatpickr'

//channel

//enabling PWA
import './companion'

//import DashboardApp from '../Dashboard.vue'
import { createApp } from 'vue'
import { createStore } from 'vuex';
import { createI18n } from 'vue-i18n';
//import messages from '@intlify/vite-plugin-vue-i18n/messages'
import VueSilentbox from 'vue-silentbox'
import 'vue-silentbox/dist/style.css'
import TurbolinksAdapter from 'vue-turbolinks'

import en from '../../../config/locales/en.json'
import fr from '../../../config/locales/fr.json'
import de from '../../../config/locales/de.json'


//Adding some filters
var truncate_filter = function(text, length, clamp){
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

//FIXME
//app.filter('truncate', truncate_filter);

const i18n = createI18n({
  locale: 'fr',
  //locale: navigator.language.split('-')[0]
  fallbackLocale: 'en',
  messages: {
    fr,
    en,
    de
  }
})


import DashboardApp from '../Dashboard.vue'


//let  dashboard_app

document.addEventListener('turbo:load', () => { 
    const element = document.querySelector("#board");

    if (element) {
      const store = createStore({
        state: {
          lists: JSON.parse(element.dataset.lists)
        },
        mutations: {
          addList(state, data) {
            state.lists.push(data)
          },
          moveList(state, data) {
            const index = state.lists.findIndex(item => item.id == data.id)
            state.lists.splice(index, 1)
            state.lists.splice(data.position - 1, 0, data)
          },
          addCard(state, data) {
            const index = state.lists.findIndex(item => item.id == data.list_id)
            state.lists[index].cards.push(data)
          },
          editCard(state, data) {
            const list_index = state.lists.findIndex((item) => item.id == data.list_id)
            const card_index = state.lists[list_index].cards.findIndex((item) => item.id == data.id)
            state.lists[list_index].cards.splice(card_index, 1, data)
          },
      
          moveCard(state, data) {
            const old_list_index = state.lists.findIndex((list) => {
              return list.cards.find((card) => {
                return card.id === data.id
              })
            })
            const old_card_index = state.lists[old_list_index].cards.findIndex((item) => item.id == data.id)
            const new_list_index = state.lists.findIndex((item) => item.id == data.list_id)
      
            if (old_list_index != new_list_index) {
              // Remove card from old list, add to new one
              state.lists[old_list_index].cards.splice(old_card_index, 1)
              state.lists[new_list_index].cards.splice(data.position - 1, 0, data)
            } else {
              state.lists[new_list_index].cards.splice(old_card_index, 1)
              state.lists[new_list_index].cards.splice(data.position - 1, 0, data)
            }
          }
        }
      });

      // detect and set the language
      var language = document.documentElement.getAttribute('lang')  
      //i18n.global.locale.value = language
      //i18n.locale=language
      i18n.global.locale = language

      const dashboard_app = createApp(DashboardApp);
      dashboard_app.use(store); // Use the Vuex store
      dashboard_app.use(VueSilentbox)
      dashboard_app.use(TurbolinksAdapter)
      dashboard_app.use(i18n)
      //app.component('dashboard-app', dashboard_app);
      dashboard_app.mount('#board');
    }
});


import VideoSessionApp from '../VideoSessionApp.vue'
document.addEventListener('turbo:load', () => { 
  let element = document.querySelector("#video_session")

  if (element) {
    let user = element.getAttribute('data-username')
    let apikey = element.getAttribute('data-apikey')
    let brand_logo = element.getAttribute('data-brandlogo')
    let brand_text = element.getAttribute('data-brandtext')
    let meeting_id = element.getAttribute('data-meetingid')

    const video_app = createApp(VideoSessionApp,{ username: user, 
                                                  apikey: apikey, 
                                                  brandlogo: brand_logo, 
                                                  brandtext: brand_text, 
                                                  meetingid: meeting_id})
    video_app.mount('#video_session')
 }
});


//$(document).ready(function(){
$(document).on('turbo:load', function() {
  $('form').on('click', '.remove_record', function(event) {
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('tr').hide();
    return event.preventDefault();
  });

  $('form').on('click', '.add_fields', function(event) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    $('.fields').append($(this).data('fields').replace(regexp, time));
    
    //Fire a custom event to update stiyles on the newly inserted element.
    // Create the event
    var custon_event = new CustomEvent("element_inserted", { "detail": "A new element was inserted in the dom." });
    // Dispatch/Trigger/Fire the event
    document.dispatchEvent(custon_event);

    return event.preventDefault();
  });
});

// some animation for alerts
//$(document).ready(function(){
$(document).on('turbo:load', function() {
  $(".alert-success" ).fadeOut(5000);
  $(".alert-info" ).fadeOut(5000);
  $(".alert-danger" ).fadeOut(5000);
  });

// force to relaod the page when internet connexion is offline to render the offline page in cache
// pwa set up
window.addEventListener('offline', () => {
  window.location.reload();
});


//TODO: This is a hack to prevent showing the backdrop 
// while using the browser back function.
// normally you should not need this. Check again after upgrading to vue3 and bootstrap 5
//setting callback function for 'hidden.bs.modal' event
//$(".modal").on('hide.bs.modal', function (e) {
//  $(document.body).removeClass('modal-open');
//  $('.modal-backdrop').remove();
//});
$(document).on('turbo:load', function() {
  $(document.body).removeClass('modal-open');
  $('.modal-backdrop').remove();
  });

  $(document).on('turbo:load', function() {
    $(".toast").toast('show');
    });
  //set locoale
  // $(document).on('turbo:load', function() {
  //   //query the locale previously set by rails
  //   var language = document.documentElement.getAttribute('lang')  
  //   //i18n.global.locale.value = language
  //   i18n.global.locale = language
  //   //console.log('langue detected: ', language)
  //   });


// document.addEventListener('turbo:load', () => {
//     var modal = document.querySelector('.modal')
//     var el =  document.getElementById("#recent-activities")
    
//     if(el) {
//         document.getElementById("#recent-activities").addEventListener('click', () => {
//             modal.classList.remove('modal--hidden');
//           });
    
//           document.querySelector(".close-modal").addEventListener('click', () => {
//             modal.classList.add('modal--hidden');
//           });
//     }
//       });

import lightbox from "lightbox2";
document.addEventListener("turbo:load", (event) => {
  lightbox.init();
});


 import Masonry from "masonry-layout"
 document.addEventListener("turbo:load", (event) => {
    var container = document.querySelector('.grid');
    if (container) {
      var msnry = new Masonry( container, {
          columnWidth: 200,
          itemSelector: '.grid-item',
          isFitWidth: true
      });
  }
});

