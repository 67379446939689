<template>

  <div class="list">
    <h6 style="font-variant: small-caps;">{{ $t(list.description) }}</h6>

    <draggable 
      v-model="list.medical_cases" 
      group="cards" 
      class="dragArea" 
      @change="cardMoved" 
      animation="500"
      preventOnFilter="true"
      delay='300' 
      delay-on-touch-only='true'
      ghost-class="ghost"
      drag-class="drag"
      _handle=".handle"
      item-key="id"
    >

    <template #item="{ element }">    
          <card  :card="element" :list="list"></card>
    </template>

    </draggable>

  </div>

</template>

<script>
import Rails from '@rails/ujs'
import draggable from 'vuedraggable'
import card from './card.vue'

export default {
  components: { card, draggable },
  props: ["list","card"],

  data: function() {
    return {
      editing: false,
      message: ""
    }
  },

  methods: {
    startEditing: function() {
      this.editing = true
      this.$nextTick(() => { this.$refs.message.focus() })
    },
    log: function(evt) {
          window.console.log(evt);
    },
    cardMoved: function(event) {
      const evt = event.added || event.moved
      if (evt == undefined) { return }

      const element = evt.element
      const list_index = this.$store.state.lists.findIndex((list) => {
        return list.medical_cases.find((card) => {
          return card.id === element.id
        })
      })

      var data = new FormData
      data.append("medical_case[list_id]", this.$store.state.lists[list_index].id)
      data.append("medical_case[position]", evt.newIndex + 1)

      Rails.ajax({
        beforeSend: () => true,
        url: `/medical_cases/${element.id}/move`,
        type: "PATCH",
        data: data,
        dataType: "json"
      })
    }
  }
}
</script>

<style scoped>
  .handle {
    float: left;
    padding-top: 8px;
    padding-bottom: 8px;
  }
</style>
