<template>
  <div id="video_session" :name="username"></div>
</template>

<script>
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt/rtc-js-prebuilt";
export default {
  name: "MedicalBoard",

  props: {
    username: String,
    apikey: String,
    brandlogo: String,
    brandtext: String,
    meetingid: String
  },

  setup(props) {
   //console.log("ID: " + import.meta.env.VITE_VIDEOSDK_MEETING_ID)
   //console.log("KEY: " + import.meta.env.VITE_VIDEOSDK_API_KEY)
  },

  mounted: async function() {
    //const apiKey = import.meta.env.VITE_VIDEOSDK_API_KEY;
    const meetingId =  'bangang_session_'+this.meetingid // import.meta.env.VITE_VIDEOSDK_MEETING_ID;
    const name = this.username;
    const api_key=this.apikey;
    const brandLogo = this.brandlogo;
    const brandText = this.brandtext;

     const config = {
      name: name,
      meetingId: meetingId,
      apiKey: api_key,

      region: "sg001", // region for new meeting

      containerId: null,
      redirectOnLeave: window.location.origin,


      micEnabled: true,
      webcamEnabled: true, 
      participantCanToggleSelfWebcam: true,
      participantCanToggleSelfMic: true,
      participantCanLeave: true, // if false, leave button won't be visible

      chatEnabled: true,
      screenShareEnabled: true,
      pollEnabled: false,
      whiteboardEnabled: true,
      raiseHandEnabled: true,

      theme: "DEFAULT", // DARK || LIGHT || DEFAULT

      recording: {
        autoStart: false, // auto start recording on participant joined
        enabled: false,
        webhookUrl: "https://www.videosdk.live/callback",
        awsDirPath: `/meeting-recordings/${meetingId}/`, // automatically save recording in this s3 path
      },

      livestream: {
        autoStart: false,
        enabled: false,
      },

      layout: {
        type: "SPOTLIGHT", // "SPOTLIGHT" | "SIDEBAR" | "GRID"
        priority: "PIN", // "SPEAKER" | "PIN",
        // gridSize: 3,
      },

      branding: {
        enabled: true,
        logoURL: brandLogo, //import.meta.env.VITE_VIDEOSDK_BRANDING_LOGO,
        name:'', //import.meta.env.VITE_VIDEOSDK_BRANDING,
        poweredBy: false,
      },
      
      permissions: {
        pin: true,
        askToJoin: false, // Ask joined participants for entry in meeting
        toggleParticipantMic: true, // Can toggle other participant's mic
        toggleParticipantWebcam: true, // Can toggle other participant's webcam
        drawOnWhiteboard: true, // Can draw on whiteboard
        toggleWhiteboard: true, // Can toggle whiteboard
        toggleRecording: true, // Can toggle meeting recording
        toggleLivestream: false, //can toggle live stream
        removeParticipant: true, // Can remove participant
        endMeeting: true, // Can end meeting
        changeLayout: true, //can change layout
      },

      joinScreen: {
        visible: true, // Show the join screen ?
        title:  brandText, //import.meta.env.VITE_VIDEOSDK_BRANDING, // Meeting title
        meetingUrl: window.location.href, // Meeting joining url
      },

      leftScreen: {
        // visible when redirect on leave not provieded
        actionButton: {
          // optional action button
          label: "Video SDK Live", // action button label
          href: "https://videosdk.live/", // action button href
        },
      },

      notificationSoundEnabled: true,

      debug: true, // pop up error during invalid config or netwrok error

      maxResolution: "sd", // "hd" or "sd"
    };
    const meeting = new VideoSDKMeeting();

    meeting.init(config);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
