import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reset-form"
export default class extends Controller {
  connect() {
    console.log("coonnected to the reset controller")
  }
  reset() {
    console.log("Resseting the form")
    this.element.reset()
   }
}
